<template>
  <v-container v-if="loadedInfo" class="d-flex justify-center flex-column align-center">
    <v-card elevation="5" rounded="xl" width="1500">
      <v-img :src="micrositeInfo.banner" width="1500" height="auto" v-if="micrositeInfo.banner" contain>
      </v-img>
      <!-- #1b273f button color -->
      <v-toolbar :height="$vuetify.breakpoint.mdAndDown ? 'auto' : '150px'"
        :color="micrositeInfo.micrositeConfiguration.primaryColor" class="d-flex flex-column"
        :class="$vuetify.breakpoint.mdAndDown ? '' : 'pl-5'">
        <v-card width="100%" color="transparent" elevation="0" class="d-flex align-center">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 7" class="d-flex justify-space-between" :class="$vuetify.breakpoint.mdAndDown
              ? 'flex-column align-center mt-5'
              : ''
              ">
              <span :style="$vuetify.breakpoint.mdAndDown
                ? 'font-size: 25px; text-align:center'
                : 'font-size: 33px;'
                " style="color: white; font-weight: 800; word-break: break-word">
                {{ micrositeInfo.name }}
              </span>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 5" class="d-flex justify-space-around pa-0" :class="$vuetify.breakpoint.mdAndDown
              ? 'flex-column align-center mb-5'
              : ''
              ">
              <v-btn height="50" :color="micrositeInfo.micrositeConfiguration.buttonColor" class="rounded-pill"
                :class="$vuetify.breakpoint.mdAndDown ? 'mb-2' : ''" :to="`/affiliation-checkout/${micrositeId}`">
                <span style="color: white; font-weight: bold" class="text-capitalize text-subtitle-1">
                  {{ micrositeInfo.organization._id == '6297b0fbc7bf8b6f826302c7' ? 'convertirse en asociado' :
                    'Convertirse en miembro' }}
                </span>
              </v-btn>
              <v-btn height="50" width="200" :color="micrositeInfo.micrositeConfiguration.buttonColor"
                class="rounded-pill" :to="`/login`">
                <span style="color: white; font-weight: bold" class="text-capitalize text-subtitle-1">Iniciar
                  Sesión</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

        <template v-slot:extension>
          <v-tabs v-model="tab" :fixed-tabs="$vuetify.breakpoint.mdAndDown" center active-class="font-weight-bold">
            <v-tabs-slider color="transparent"></v-tabs-slider>
            <v-tab class="white--text text-capitalize">
              {{ items[0].title }}
            </v-tab>
            <v-divider inset vertical style="background: white"></v-divider>
            <v-tab class="white--text text-capitalize">
              {{ micrositeInfo.organization._id == '6297b0fbc7bf8b6f826302c7' ? 'Directorio de asociados' :
                items[1].title }}
            </v-tab>
            <v-divider inset vertical style="background: white"></v-divider>
            <v-tab class="white--text text-capitalize">
              {{ items[2].title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <!-- First Page -->
        <v-tab-item>
          <v-card flat>
            <Home :description="micrositeInfo.description" :video="micrositeInfo.youtubeVideo"
              :organization="micrositeInfo.organization" :socialMedia="micrositeInfo.socialMedia"
              :primaryColor="micrositeInfo.micrositeConfiguration.primaryColor"
              :emailContact="micrositeInfo.emailContact" :sponsors="micrositeInfo.sponsors" :faqs="micrositeInfo.faqs"
              :flyer="micrositeInfo.flyer" />
          </v-card>
        </v-tab-item>

        <!-- Second Page -->
        <v-tab-item>
          <v-card color="#f9fafc" width="100%" height="100%">
            <Members :color="micrositeInfo.micrositeConfiguration.primaryColor"
              :profilePicture="micrositeInfo.organization.profilePicture" />
          </v-card>
        </v-tab-item>

        <!-- Third Page -->
        <v-tab-item>
          <v-card color="#f9fafc" width="100%" height="100%">
            <Events :color="micrositeInfo.micrositeConfiguration.primaryColor" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- Footer -->
    <v-row class="my-10 grayscale-disabled-element">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img max-width="150" height="auto" src="@/assets/images/weChamber/poweredby.svg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Home from './tabs/Home.vue';
import Members from './tabs/Members.vue';
import Events from './tabs/Events.vue';
import { mapActions, mapMutations } from 'vuex';
import microsite from '../../../store/microsite';

export default {
  components: {
    Home,
    Members,
    Events,
  },
  data() {
    return {
      tab: null,
      items: [
        { title: 'Inicio', rBar: true },
        { title: 'Directorio de Miembros', rBar: true },
        { title: 'Cursos', rBar: false },
      ],
      micrositeInfo: {},
      loadedInfo: false,
      micrositeEvents: [],
      loadingEvents: false,
      noMoreEvents: false,
      eventsPage: 1,
      eventSearch: '',
    };
  },
  computed: {
    micrositeId() {
      if (this.micrositeInfo.headOfficeAffiliation) {
        return this.micrositeInfo.headOfficeAffiliation;
      }
      return this.$route.params.id;
    },
    filteredEvents() {
      if (this.eventSearch == '') {
        return this.micrositeEvents;
      } else {
        return this.micrositeEvents.filter((event) => {
          return (
            event.title
              .toLowerCase()
              .includes(this.eventSearch.toLowerCase()) ||
            event.description
              .toLowerCase()
              .includes(this.eventSearch.toLowerCase())
          );
        });
      }
    },
    isCanacoLeon() {
      return this.micrositeInfo.organization._id == '6500e21c80d167001bf44b63';
    },
  },
  methods: {
    ...mapActions('microsite', ['getMicrositeInfo']),
    ...mapMutations('loader', ['loading', 'loaded']),
    async getMicrosite() {
      try {
        this.loading();
        const microsite = await this.getMicrositeInfo(this.$route.params.id);
        if (microsite.status) {
          this.micrositeInfo = microsite.data;
        } else {
          console.log(microsite.message);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.loadedInfo = true;
      }
    },

    /*handleScroll() {
      window.onscroll = async () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight + 50 >=
          document.documentElement.offsetHeight;
        console.log(bottomOfWindow);
        if (bottomOfWindow && !this.loadingMembers) {
          if (this.tab == 1) {
            if (this.memberSearch == "" && !this.noMoreMembers) {
              await this.getMembers();
            } else {
              if (!this.noMoreResults && this.membersSearchPage > 1) {
                await this.getMembersSearch(false);
              }
            }
          } else if (this.tab == 2 && !this.noMoreEvents) {
            await this.getEvents();
          }
        }
      };
    },*/
  },
  created() {
    this.getMicrosite();
    if (this.$route.query.step == 'members') {
      this.tab = 1;
    } else if (this.$route.query.step == 'events') {
      this.tab = 2;
    }
  },
};
</script>

<style scoped>
input::placeholder {
  font-size: 15px;
  color: #d4dcd4;
}

input {
  padding-left: 20px;
  width: 30rem;
  max-width: 30rem;
  min-width: 10rem;
  border: 0;
  border-color: transparent;
  background-color: #f9fafc;
}

input:focus {
  outline: none;
  border: 1px solid transparent;
}

p {
  font-size: 10px;
  text-transform: uppercase;
  color: #222b4c;
}

span {
  color: #222b4c;
  font-weight: 900;
}
</style>
